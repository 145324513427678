import type { FC } from "react";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { BillingApp } from "billing/lib";
import { DeliveryApp } from "delivery/lib";
import { DevicesApp } from "devices/lib";
import { paths } from "@omnic/lk-core";
import { AxiosProvider, ProtectedRoute, ScopeAccessRoute, SpinnerOverlay } from "@omnic/lk-ui";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { TFnType } from "@tolgee/web";

import { AuthProvider } from "./layouts/AuthProvider";
import { PageLayout } from "./layouts/PageLayout";

import "./styles/index.scss";
import "@omnic/lk-ui/dist/style.css";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: paths.root,
    element: <AuthProvider />,
    children: [
      {
        element: <AxiosProvider baseURL={import.meta.env.VITE_HUB_API_URL} />,
        children: [
          {
            element: <ProtectedRoute />,
            children: [
              {
                element: <PageLayout />,
                children: [
                  // --- devices microservice ---
                  {
                    element: <ScopeAccessRoute access="*" />,
                    children: [
                      {
                        path: paths.devices.root,
                        handle: { crumb: (t: TFnType) => t("devices.title") },
                        children: DevicesApp.router,
                      },
                      {
                        index: true,
                        element: <Navigate to={paths.devices.root} />,
                      },
                    ],
                  },

                  // --- billing microservice ---
                  {
                    element: <ScopeAccessRoute access="de:billing:user" />,
                    children: [
                      {
                        path: paths.billing.root,
                        handle: { crumb: (t: TFnType) => t("billing.title") },
                        children: BillingApp.router,
                      },
                      {
                        index: true,
                        element: <Navigate to={paths.billing.root} />,
                      },
                    ],
                  },

                  // --- delivery microservice ---
                  {
                    element: <ScopeAccessRoute access="ec:pc:allowed" />,
                    children: [
                      {
                        path: paths.delivery.root,
                        handle: { crumb: (t: TFnType) => t("delivery.title") },
                        children: DeliveryApp.router,
                      },
                      {
                        index: true,
                        element: <Navigate to={paths.delivery.root} />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: paths.login,
        element: <Navigate to={paths.root} />,
      },
    ],
  },
]);

export const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      {import.meta.env.DEV && <ReactQueryDevtools initialIsOpen={false} />}
      <Toaster position="top-right" />
      <RouterProvider fallbackElement={<SpinnerOverlay />} router={router} />
    </QueryClientProvider>
  );
};
