import { FormatIcu } from "@tolgee/format-icu";
import { DevBackend } from "@tolgee/react";
import { Tolgee } from "@tolgee/web";

export const tolgee = Tolgee()
  .use(DevBackend())
  .use(FormatIcu())
  .init({
    language: "en",
    apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
  });
