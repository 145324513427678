import ReactDOM from "react-dom/client";
import { TolgeeProvider } from "@tolgee/react";

import { App } from "./_app";
import { tolgee } from "./i18n";

import "./styles/index.scss";
import "@omnic/lk-ui/dist/style.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <TolgeeProvider tolgee={tolgee}>
    <App />
  </TolgeeProvider>,
);
