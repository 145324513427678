import { type FC } from "react";
import { Outlet } from "react-router-dom";
import { SidebarProvider, useSidebar } from "src/context";

import { Header } from "../Header";
import { Sidebar } from "../Sidebar";

import type { PageLayoutProps } from "./PageLayout.types";

import styles from "./PageLayout.module.scss";

export const PageLayoutContent: FC<PageLayoutProps> = ({ className }) => {
  const { selected } = useSidebar();

  return (
    <main className={cn(styles.root, className, { [styles.expanded]: !!selected })}>
      <Header className={styles.header} />
      <Sidebar className={styles.sidebar} />

      <div className={styles.container}>
        <section className={styles.content}>
          <Outlet />
        </section>
      </div>
    </main>
  );
};

export const PageLayout: FC<PageLayoutProps> = (props) => {
  return (
    <SidebarProvider>
      <PageLayoutContent {...props} />
    </SidebarProvider>
  );
};
