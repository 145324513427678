import { type FC, useState } from "react";
import { useAuth } from "oidc-react";
import { UserIcon } from "src/assets/icons";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useCurrentUserStore } from "@omnic/lk-core";
import { useTranslate } from "@tolgee/react";

import type { ProfileButtonProps } from "./ProfileButton.types";

import styles from "./ProfileButton.module.scss";

export const ProfileButton: FC<ProfileButtonProps> = ({ className }) => {
  const { t } = useTranslate();

  const { signOut } = useAuth();
  const { user } = useCurrentUserStore();

  const [isOpen, setIsOpen] = useState(false);

  const { context, floatingStyles, refs } = useFloating({
    open: isOpen,
    placement: "bottom-start",
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, role]);

  // const handleListItemClick = () => {
  //   setIsOpen(false);
  // };

  const handleLogOut = async () => {
    setIsOpen(false);
    await signOut();
  };

  return (
    <>
      <button ref={refs.setReference} type="button" className={cn(styles.root, className)} {...getReferenceProps()}>
        <span className={styles.title}>{user?.login}</span>
        <UserIcon />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} style={floatingStyles} className={styles.list} {...getFloatingProps()}>
            {/* NOTE: hidden in https://omnicampus.atlassian.net/browse/OCHN-2115 */}
            {/* <div role="presentation" className={styles.listItem} onClick={handleListItemClick}> */}
            {/*   {t("change_password")} */}
            {/* </div> */}
            {/* <div className={styles.listSeparator} /> */}
            <div role="presentation" className={styles.listItem} onClick={handleLogOut}>
              {t("log_out")}
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
