/* eslint-disable jsx-a11y/role-supports-aria-props */

import type { FC } from "react";
import groupBy from "lodash.groupby";
import { useAuth } from "oidc-react";
import { NavigationItem } from "src/components/NavigationItem";
import { useSidebarMenuItems } from "src/constants";
import { hasScopeAccess } from "@omnic/lk-core";
import { useTranslate } from "@tolgee/react";

import { useSidebar } from "../../context/sidebar";

import type { SidebarProps } from "./Sidebar.types";

import styles from "./Sidebar.module.scss";

export const Sidebar: FC<SidebarProps> = ({ className }) => {
  const authContext = useAuth();

  const { t } = useTranslate();
  const { selected } = useSidebar();

  const sidebarMenuItems = useSidebarMenuItems();
  const groupedMenuItems = groupBy(sidebarMenuItems, (item) => item.group);

  return (
    <>
      <aside aria-expanded={!!selected} className={cn(styles.root, className)}>
        {Object.entries(groupedMenuItems)
          .filter(([, menuItems]) =>
            // filter out group if every item in group has no access
            menuItems.every((menuItem) => !menuItem.access || hasScopeAccess(menuItem.access)(authContext)),
          )
          .map(([key, menuItems]) => {
            return (
              <div key={key} className={styles.group}>
                <div className={styles.groupTitle}>{t(`sidebar.groups.${key}`)}</div>
                <ul className={styles.groupList}>
                  {menuItems?.map((menuItem) => {
                    // filter out menu elements with no access
                    if (!!menuItem.access && !hasScopeAccess(menuItem.access)(authContext)) {
                      return null;
                    }

                    return <NavigationItem key={menuItem.id} menuItem={menuItem} />;
                  })}
                </ul>
              </div>
            );
          })}
      </aside>

      {!!selected && (
        <aside className={styles.slideout}>
          <ul className={styles.groupList}>
            {selected.children
              ?.filter((menuItem) => !menuItem.access || hasScopeAccess(menuItem.access)(authContext))
              ?.map((menuItem) => <NavigationItem key={menuItem.id} isChild menuItem={menuItem} />)}
          </ul>
        </aside>
      )}
    </>
  );
};
