import type { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";
import type { SidebarMenuItem } from "src/types";

export interface SidebarContextProps {
  selected: SidebarMenuItem | null;
  setSelected: Dispatch<SetStateAction<SidebarMenuItem | null>>;
}

export const SidebarContext = createContext<SidebarContextProps>({} as SidebarContextProps);

export const useSidebar = () => useContext(SidebarContext);

export interface SidebarContextProviderProps {
  children: ReactNode;
}

export const SidebarProvider: FC<SidebarContextProviderProps> = ({ children }) => {
  const [selected, setSelected] = useState<SidebarMenuItem | null>(null);

  const value = useMemo(
    () => ({
      selected,
      setSelected,
    }),
    [selected, setSelected],
  );

  return <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>;
};
