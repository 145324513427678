import type { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { match, P } from "ts-pattern";
import { AuthProvider as OmnicAuthProvider } from "@omnic/lk-ui";

export const AuthProvider: FC = () => {
  const [params] = useSearchParams();

  const queryParams = [...params.entries()]
    // whitelist params to prevent doubled OIDC state
    .filter(([key]) => ["ct", "logo"].includes(key))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Dictionary<string>);

  if (import.meta.env.VITE_LOGO) {
    queryParams.logo = import.meta.env.VITE_LOGO;
  }

  const authority = match(queryParams)
    .with({ ct: P.string }, () => `${import.meta.env.VITE_OIDC_AUTHORITY}invite/`)
    .otherwise(() => import.meta.env.VITE_OIDC_AUTHORITY);

  return (
    <OmnicAuthProvider
      authority={authority}
      extraQueryParams={queryParams}
      clientId={import.meta.env.VITE_OIDC_CLIENT_ID}
      redirectUri={import.meta.env.VITE_OIDC_REDIRECT_URL}
    />
  );
};
