import { type FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "oidc-react";
import { LogoIcon } from "src/assets/icons";
import { Breadcrumbs } from "src/components/Breadcrumbs";
import { LocaleSelector } from "src/components/LocaleSelector";
import { ProfileButton } from "src/components/ProfileButton";
import { useCurrentUser } from "src/hooks";
import { Spinner } from "@omnic/lk-ui";

import type { HeaderProps } from "./Header.types";

import styles from "./Header.module.scss";

export const Header: FC<HeaderProps> = ({ className }) => {
  const { userManager } = useAuth();
  const { data: user, isFetched: isUserLoaded, isLoading: isUserLoading } = useCurrentUser();

  const organization = user?.data.data.organization;
  const hasOrganizationLogo = !!organization?.logo;

  useEffect(() => {
    if (!organization?.logout) return;

    for (const [key, value] of Object.entries(organization.logout)) {
      userManager.settings.extraQueryParams[key] = value;
    }
  }, [organization?.logout, userManager.settings]);

  return (
    <nav className={cn(styles.root, className)}>
      <Link className={styles.logo} to="/">
        {isUserLoading && !isUserLoaded && <Spinner className={styles.spinner} />}
        {isUserLoaded && hasOrganizationLogo && (
          <img
            src={user?.data.data.organization.logo}
            alt={user?.data.data.organization.name}
            className={styles.logoImage}
          />
        )}
        {isUserLoaded && !hasOrganizationLogo && <LogoIcon />}
      </Link>

      <div className={styles.content}>
        <Breadcrumbs className={styles.breadcrumbs} />

        <div className={styles.actions}>
          <LocaleSelector />
          {/* <EComButton /> */}
          <ProfileButton />
        </div>
      </div>
    </nav>
  );
};
