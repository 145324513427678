import { type FC, useState } from "react";
import { ChevronDownIcon } from "src/assets/icons";
import { tolgee } from "src/i18n";
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";

import type { LocaleSelectorProps } from "./LocaleSelector.types";

import styles from "./LocaleSelector.module.scss";

export const LocaleSelector: FC<LocaleSelectorProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState("EN");

  const { context, floatingStyles, refs } = useFloating({
    open: isOpen,
    placement: "bottom-start",
    onOpenChange: setIsOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, role]);

  const handleSetLocale = (locale: string) => () => {
    setSelectedLocale(locale);
    setIsOpen(false);
    tolgee.changeLanguage(locale.toLowerCase());
  };

  return (
    <>
      <button ref={refs.setReference} type="button" className={cn(styles.root, className)} {...getReferenceProps()}>
        <span className={styles.title}>{selectedLocale}</span>
        <ChevronDownIcon className={styles.icon} />
      </button>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div ref={refs.setFloating} style={floatingStyles} className={styles.list} {...getFloatingProps()}>
            <div role="presentation" className={styles.listItem} onClick={handleSetLocale("EN")}>
              EN
            </div>
            <div role="presentation" className={styles.listItem} onClick={handleSetLocale("AR")}>
              AR
            </div>
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
};
