import { type FC } from "react";
import { Link, useMatch } from "react-router-dom";
import { useSidebar } from "src/context";
import { ArrowRightIcon } from "@omnic/lk-ui";

import type { NavigationItemProps } from "./NavigationItem.types";

import styles from "./NavigationItem.module.scss";

export const NavigationItem: FC<NavigationItemProps> = ({ className, isChild, menuItem }) => {
  const match = useMatch(menuItem.path);

  const { selected, setSelected } = useSidebar();

  const handleNavClick = () => {
    setSelected((prevSelected) => {
      if (prevSelected?.id === menuItem.id) {
        return null;
      }

      return menuItem;
    });
  };

  const rootClasses = {
    [styles.root]: !isChild,
    [styles.child]: isChild,
  };

  const activeClasses = {
    [styles.active]: !!match,
  };

  const openClasses = {
    [styles.open]: menuItem.id === selected?.id,
  };

  if (menuItem.children?.length) {
    return (
      <div
        role="presentation"
        className={cn(rootClasses, className, activeClasses, openClasses)}
        onClick={handleNavClick}
      >
        {menuItem.icon && <img className={styles.icon} src={menuItem.icon} alt={menuItem.title} />}
        {menuItem.title}
        <ArrowRightIcon width={20} height={20} className={styles.arrow} />
      </div>
    );
  }

  return (
    <Link
      state={menuItem.data}
      className={cn(rootClasses, activeClasses, className)}
      to={menuItem.path}
      onClick={() => setSelected(null)}
    >
      {menuItem.icon && <img className={styles.icon} src={menuItem.icon} alt={menuItem.title} />}
      {isChild && <ArrowRightIcon width={16} height={16} />}
      {menuItem.title}
    </Link>
  );
};
